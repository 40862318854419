import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OperationObserverService } from '@x/common/operation';
import { coerceDateTime } from '@x/common/utils';
import { IStockScheduleItemObject, StockScheduleService } from '@x/ecommerce/domain-client';
import {
  StockItemItemCollectionProvider,
  WarehouseItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { ScheduleInterval } from '@x/schemas/ecommerce';
import { tap } from 'rxjs/operators';

export interface StockScheduleFormDialogData {
  id?: number | null;
  stockItemId?: number | null;
  warehouseId?: number | null;
}

export type StockScheduleFormDialogResult = IStockScheduleItemObject | undefined;

type ScheduleFormGroup = FormGroup<{
  quantity: FormControl<number | null>;
  warehouseId: FormControl<number | null>;
  stockItemId: FormControl<number | null>;
  name: FormControl<string>;
  days: FormControl<number[]>;
  interval: FormControl<ScheduleInterval>;
  tracked: FormControl<boolean>;
  priority: FormControl<number>;
  shelfLife: FormControl<string | null>;
  endAt: FormControl<string | null>;
  startAt: FormControl<string | null>;
  enabled: FormControl<boolean>;
}>;

@Component({
  selector: 'x-stock-schedule-form-dialog',
  templateUrl: 'stock-schedule-form-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockScheduleFormDialogComponent implements OnInit {
  get quantityControl() {
    return this.formGroup?.get('quantity');
  }

  get trackedControl() {
    return this.formGroup?.get('tracked');
  }

  get intervalControl() {
    return this.formGroup?.get('interval');
  }

  Providers = {
    WarehouseItemCollectionProvider,
    StockItemItemCollectionProvider,
  };

  formGroup?: ScheduleFormGroup;

  availableDays: { value: string | number; label: string }[] = [];
  isWeeklySchedule: boolean;
  scheduleIntervals: ScheduleInterval[] = Object.values(ScheduleInterval);

  constructor(
    private stockScheduleService: StockScheduleService,
    @Inject(MAT_DIALOG_DATA)
    public data: StockScheduleFormDialogData,
    private operations: OperationObserverService,
    private dialogRef: MatDialogRef<
      StockScheduleFormDialogComponent,
      StockScheduleFormDialogResult
    >,
    private changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.data.id) {
      this.stockScheduleService.fetchItem(this.data.id).subscribe({
        next: (schedule) => {
          this.initUpdateForm(schedule);
          this.changeRef.markForCheck();
        },
        error: (error) => (this.formGroup = undefined),
      });
    } else {
      this.initNewForm();
      this.changeRef.markForCheck();
    }
  }

  initNewForm() {
    const { stockItemId, warehouseId } = this.data;

    this.formGroup = new FormGroup({
      name: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      warehouseId: new FormControl<number | null>(warehouseId ?? 0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      stockItemId: new FormControl<number | null>(stockItemId ?? 0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      quantity: new FormControl<number | null>(0, [Validators.required, Validators.min(1)]),
      priority: new FormControl<number>(1, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      shelfLife: new FormControl<string | null>(null),
      startAt: new FormControl<string | null>(null),
      endAt: new FormControl<string | null>(null),
      interval: new FormControl<ScheduleInterval>(ScheduleInterval.Weekly, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      days: new FormControl<number[]>([], {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(1)],
      }),
      tracked: new FormControl<boolean>(true, { nonNullable: true }),
      enabled: new FormControl<boolean>(true, { nonNullable: true }),
    });

    this.updateTrackedState();
    this.updateAvailableDays(ScheduleInterval.Weekly);

    this.trackedControl?.valueChanges.subscribe((v) => {
      this.updateTrackedState();
    });
    this.intervalControl?.valueChanges.subscribe((v) => {
      this.updateAvailableDays(v);
    });
  }

  initUpdateForm(schedule: IStockScheduleItemObject) {
    const transformedDays = schedule.days ? schedule.days.split(',').map(Number) : [];

    this.formGroup = new FormGroup({
      name: new FormControl<string>(schedule.name, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      warehouseId: new FormControl<number | null>(
        { value: schedule.warehouse.id, disabled: true },
        {
          nonNullable: true,
          validators: [Validators.required],
        },
      ),
      stockItemId: new FormControl<number | null>(
        { value: schedule.item.id, disabled: true },
        {
          nonNullable: true,
          validators: [Validators.required],
        },
      ),
      quantity: new FormControl<number | null>(
        { value: schedule.quantity, disabled: !schedule.tracked },
        [Validators.required, Validators.min(1)],
      ),
      priority: new FormControl<number>(schedule.priority, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      shelfLife: new FormControl<string | null>(schedule.shelfLife),
      startAt: new FormControl<string | null>(schedule.startAt ?? null),
      endAt: new FormControl<string | null>(schedule.endAt ?? null),
      interval: new FormControl<ScheduleInterval>(schedule.interval, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      days: new FormControl<number[]>(transformedDays, {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(1)],
      }),
      tracked: new FormControl<boolean>(schedule.tracked, { nonNullable: true }),
      enabled: new FormControl<boolean>(schedule.enabled, { nonNullable: true }),
    });

    this.updateTrackedState();
    this.updateAvailableDays(schedule.interval);

    this.trackedControl?.valueChanges.subscribe((v) => {
      this.updateTrackedState();
    });
    this.intervalControl?.valueChanges.subscribe((v) => {
      this.updateAvailableDays(v);
    });
  }

  submit() {
    console.log(';SUBMIT');
    if (!this.formGroup) return;

    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid) {
      const form = this.formGroup.getRawValue();

      const days = Array.isArray(form.days) && form.days.length > 0 ? form.days.join(',') : '';

      const basePayload = {
        ...form,
        days,
        startAt: coerceDateTime(form.startAt)?.toISO(),
        endAt: coerceDateTime(form.endAt)?.toISO(),
      };
      const stockItemId = this.data.stockItemId;
      let operation$;

      if (this.data.id) {
        const { warehouseId, stockItemId, ...updatePayload } = basePayload;
        operation$ = this.stockScheduleService.update({
          ...updatePayload,
          id: this.data.id,
        });
      } else {
        operation$ = this.stockScheduleService.create({
          ...basePayload,
          warehouseId: basePayload.warehouseId ?? 0,
          stockItemId: basePayload.stockItemId ?? 0,
        });
      }

      this.operations
        .observe(operation$)
        .pipe(
          tap((state) => {
            if (state.isSuccessState()) {
              this.dialogRef.close(state.data);
            }
          }),
        )
        .subscribe();
    } else {
      console.warn(this.formGroup.errors);
    }
  }

  updateTrackedState() {
    const tracked = this.formGroup?.value.tracked;
    if (!tracked) {
      this.quantityControl?.disable({ emitEvent: false });
    } else {
      this.quantityControl?.enable({ emitEvent: false });
    }
  }

  onIntervalChange(value: ScheduleInterval): void {
    this.isWeeklySchedule = value === ScheduleInterval.Weekly;
    this.updateAvailableDays(value);
  }

  private updateAvailableDays(interval: ScheduleInterval): void {
    this.availableDays =
      interval === ScheduleInterval.Weekly ? this.getWeekDays() : this.getMonthDays();
    this.isWeeklySchedule = interval === ScheduleInterval.Weekly;
    const control = this.formGroup?.get('days');

    if (!control) return;

    let value = control.value.filter((n) => this.availableDays.some((i) => i.value === n));
    control.setValue(value, { emitEvent: false });
  }

  private getWeekDays() {
    return [
      { value: 1, label: 'Monday' },
      { value: 2, label: 'Tuesday' },
      { value: 3, label: 'Wednesday' },
      { value: 4, label: 'Thursday' },
      { value: 5, label: 'Friday' },
      { value: 6, label: 'Saturday' },
      { value: 7, label: 'Sunday' },
    ];
  }

  private getMonthDays() {
    return Array.from({ length: 31 }, (_, i) => ({
      value: i + 1,
      label: `${i + 1}`,
    }));
  }
}
