import { Pipe, PipeTransform } from '@angular/core';
import { ScheduleInterval } from '@x/schemas/ecommerce';

@Pipe({
  standalone: true,
  name: 'intervalLabel',
})
export class IntervalLabelPipe implements PipeTransform {
  transform(interval: ScheduleInterval): string {
    const labels: Record<ScheduleInterval, string> = {
      [ScheduleInterval.Weekly]: 'Weekly',
      [ScheduleInterval.Monthly]: 'Monthly',
    };

    return labels[interval] || interval;
  }
}
