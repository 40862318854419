import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CronModule } from '@x/common/cron';
import { IntervalLabelPipe } from '@x/common/datetime/pipes/interval-label.pipe';
import { LocaleModule } from '@x/common/locale';
import { ChannelSharedModule } from '../channel/channel-shared.module';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { ProductsSharedModule } from '../products/products-shared.module';
import { StockCategoryAutocompleteComponent } from './components/stock-category-autocomplete/stock-category-autocomplete.component';
import { StockCategoryCreateDialogComponent } from './components/stock-category-create-dialog/stock-category-create-dialog.component';
import { StockCategoryUpdateDialogComponent } from './components/stock-category-update-dialog/stock-category-update-dialog.component';
import { StockItemComponentFormDialogComponent } from './components/stock-item-component-form-dialog/stock-item-component-form-dialog.component';
import { StockItemFormDialogComponent } from './components/stock-item-form-dialog/stock-item-form-dialog.component';
import { StockLotFormDialogComponent } from './components/stock-lot-form-dialog/stock-lot-form-dialog.component';
import { StockScheduleFormDialogComponent } from './components/stock-schedule-form-dialog/stock-schedule-form-dialog.component';
import { WarehouseFormDialogComponent } from './components/warehouse-form-dialog/warehouse-form-dialog.component';
import { INVENTORY_LANGUAGE } from './inventory.language';
import { StockPriorityLabelPipe } from './pipes/stock-priority.pipe';
import { StockCategoryDialogService } from './services/stock-category-dialog.service';
import { StockItemDialogService } from './services/stock-item-dialog.service';
import { WarehouseDialogService } from './services/warehouse-dialog.service';

@NgModule({
  declarations: [
    WarehouseFormDialogComponent,
    StockItemFormDialogComponent,
    StockCategoryAutocompleteComponent,
    StockCategoryCreateDialogComponent,
    StockCategoryUpdateDialogComponent,
    StockScheduleFormDialogComponent,
    StockLotFormDialogComponent,
    StockPriorityLabelPipe,
    StockItemComponentFormDialogComponent,
  ],
  imports: [
    CommonModule,
    ProductsSharedModule,
    EcommerceAdminCoreModule,
    ChannelSharedModule,
    CronModule,
    LocaleModule.forChild([INVENTORY_LANGUAGE]),
    IntervalLabelPipe,
  ],
  providers: [WarehouseDialogService, StockItemDialogService, StockCategoryDialogService],
  exports: [
    StockItemFormDialogComponent,
    StockCategoryAutocompleteComponent,
    StockCategoryCreateDialogComponent,
    StockCategoryUpdateDialogComponent,
    StockPriorityLabelPipe,
  ],
})
export class InventorySharedModule {}
